import React from "react";
import "./index.css";

export default ({fill = "rgba(0,0,0,0.65)", style = {}, className = "icon", viewBox = "0 0 1024 1024"}) => (
  <svg
    style={style}
    viewBox={viewBox}
    className={className}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path d="M936.228571 438.857143L804.571429 109.714286c0-7.314286-7.314286-14.628571-14.628572-21.942857-7.314286-7.314286-14.628571-7.314286-29.257143-7.314286h-512c-7.314286 0-21.942857 0-29.257143 7.314286-7.314286 7.314286-7.314286 14.628571-14.628571 21.942857L87.771429 431.542857V877.714286c0 21.942857 7.314286 36.571429 21.942857 51.2 14.628571 14.628571 29.257143 21.942857 51.2 21.942857H877.714286c21.942857 0 36.571429-7.314286 51.2-21.942857 14.628571-14.628571 21.942857-29.257143 21.942857-51.2V460.8v-7.314286l-14.628572-14.628571z m-336.457142-51.2v14.628571c0 29.257143-7.314286 43.885714-21.942858 65.828572s-36.571429 21.942857-65.828571 21.942857-43.885714-7.314286-65.828571-21.942857c-14.628571-14.628571-21.942857-36.571429-21.942858-65.828572v-14.628571H190.171429l87.771428-219.428572H731.428571l95.085715 219.428572H599.771429zM168.228571 475.428571h182.857143c14.628571 29.257143 36.571429 58.514286 65.828572 73.142858 29.257143 21.942857 58.514286 29.257143 95.085714 29.257142s73.142857-7.314286 102.4-29.257142c29.257143-14.628571 43.885714-43.885714 58.514286-73.142858h182.857143v380.342858H168.228571V475.428571z" />
  </svg>
);
