import React from "react";
import "./index.css";

export default ({fill = "rgba(0,0,0,0.65)", style = {}, className = "icon", viewBox = "0 0 1024 1024"}) => (
  <svg
    style={style}
    viewBox={viewBox}
    className={className}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      d="M512 16C238 16 16 238 16 512s222 496 496 496 496-222 496-496S786 16 512 16z m256 843.2c-71.8 53-160.2 84.8-256 84.8s-184.2-31.8-256-84.8V832c0-70.6 57.4-128 128-128 22.2 0 55 22.8 128 22.8 73.2 0 105.6-22.8 128-22.8 70.6 0 128 57.4 128 128v27.2z m61.2-55c-13.6-92.8-92.6-164.2-189.2-164.2-41 0-60.8 22.8-128 22.8S425.2 640 384 640c-96.6 0-175.6 71.4-189.2 164.2C123.8 727.2 80 624.8 80 512c0-238.2 193.8-432 432-432s432 193.8 432 432c0 112.8-43.8 215.2-114.8 292.2zM512 240c-97.2 0-176 78.8-176 176s78.8 176 176 176 176-78.8 176-176-78.8-176-176-176z m0 288c-61.8 0-112-50.2-112-112s50.2-112 112-112 112 50.2 112 112-50.2 112-112 112z"
      p-id="5538"
    />
  </svg>
);
