export default `/*

XCode style (c) Angel Garcia <angelgarcia.mail@gmail.com>

*/

.hljs {
  display: block;
  overflow-x: auto;
  padding: 16px;
  background: #fff;
  color: black;
}

/* Gray DOCTYPE selectors like WebKit */
.xml .hljs-meta {
  color: #c0c0c0;
}

.hljs-comment,
.hljs-quote {
  color: #007400;
}

.hljs-tag,
.hljs-attribute,
.hljs-keyword,
.hljs-selector-tag,
.hljs-literal,
.hljs-name {
  color: #aa0d91;
}

.hljs-variable,
.hljs-template-variable {
  color: #3F6E74;
}

.hljs-code,
.hljs-string,
.hljs-meta-string {
  color: #c41a16;
}

.hljs-regexp,
.hljs-link {
  color: #0E0EFF;
}

.hljs-title,
.hljs-symbol,
.hljs-bullet,
.hljs-number {
  color: #1c00cf;
}

.hljs-section,
.hljs-meta {
  color: #643820;
}


.hljs-class .hljs-title,
.hljs-type,
.hljs-built_in,
.hljs-builtin-name,
.hljs-params {
  color: #5c2699;
}

.hljs-attr {
  color: #836C28;
}

.hljs-subst {
  color: #000;
}

.hljs-formula {
  background-color: #eee;
  font-style: italic;
}

.hljs-addition {
  background-color: #baeeba;
}

.hljs-deletion {
  background-color: #ffc8bd;
}

.hljs-selector-id,
.hljs-selector-class {
  color: #9b703f;
}

.hljs-doctag,
.hljs-strong {
  font-weight: bold;
}

.hljs-emphasis {
  font-style: italic;
}`;
